class InitForm {
	static inputFormStyle() {
		$('.input-group .form-control').on('focus blur', function () {
			$(this).parents('.input-group').toggleClass('focus')
		})

		$('.btn[data-action="show-password"]').on('click', function () {
			const $this = $(this),
			$formControl = $this.parents('.input-group').find('.form-control')

			if ($formControl.attr('type') == 'password') {
				$this.find('i').removeClass('fa-eye').addClass('fa-eye-slash')
				$formControl.attr('type', 'text')
			} else {
				$this.find('i').removeClass('fa-eye-slash').addClass('fa-eye')
				$formControl.attr('type', 'password')
			}
		})

		$.fn.select2.defaults.set('theme', 'bootstrap4')
		$.fn.select2.defaults.set('dropdownCssClass', 'text-capitalize')
		$.fn.select2.defaults.set('containerCssClass', 'text-capitalize')

		$('.form-img').on('change', function () {
			readImage(this)
			$(this).parents('.form-upload').removeClass('invalid')
		})
	}

	static inputFormat () {
		$('.form-control[type="tel"], .form-control[type=number]').inputFilter(function (value) {
			return /^(?:[1-9][0-9]*)$/.test(value)
		})

		$('.form-control[type=number]').on('wheel keydown', function (e) {
			if (e.type === 'wheel' || e.which === 38 || e.which === 40 || e.keyCode === 190 || e.keyCode === 110) e.preventDefault()
		})

		// $('.form-control[data-type="weight"]').inputFilter(function (value) {
		// 	console.log('a');
		// 	return /^(\d+)(\d{3})$/.test(value)
		// })

		// $('.form-control[data-type="currency"]').inputFilter(function (value) {
		// 	return /^(\d*\.\d{1,2}|\d+)$/.test(value)
		// })

		const formCurrency = '.form-control[data-type="currency"]'
		if ($(formCurrency).length > 0) {
			$(formCurrency).each(function () {
				new AutoNumeric(this, {
					digitGroupSeparator: '.',
					decimalCharacter: ',',
					decimalPlaces: 0,
					emptyInputBehavior: 'null',
					minimumValue: '1',
					modifyValueOnWheel: false,
					overrideMinMaxLimits: 'ignore'
				})
			})
		}

		const formWeight = '.form-control[data-type="weight"]'
		if ($(formWeight).length > 0) {
			$(formWeight).each(function () {
				new AutoNumeric(this, {
					digitGroupSeparator: '.',
					decimalCharacter: ',',
					decimalPlaces: 2,
					emptyInputBehavior: 'zero',
					leadingZero: 'keep',
					minimumValue: '0',
					modifyValueOnWheel: false,
					unformatOnSubmit: true
				})
			})
        }

        const formNumber = '.form-control[data-type="number"]'
		if ($(formNumber).length > 0) {
			$(formNumber).each(function () {
				new AutoNumeric(this, {
					digitGroupSeparator: '',
					decimalCharacter: ',',
					decimalPlaces: 0,
					emptyInputBehavior: 'zero',
					leadingZero: 'keep',
					minimumValue: '0',
					modifyValueOnWheel: false,
					unformatOnSubmit: true
				})
			})
		}

		$('.form-control[data-type="weight"], .form-control[data-type="currency"]').on('input', function () {
			if ($(this).val() == '-' || $(this).val() == '+') $(this).val('')
		})
	}
}

(function($) {
  $.fn.inputFilter = function(inputFilter) {
    return this.on('input keydown keyup mousedown mouseup select contextmenu drop', function() {
      if (inputFilter(this.value)) {
        this.oldValue = this.value;
        this.oldSelectionStart = this.selectionStart;
        this.oldSelectionEnd = this.selectionEnd;
      } else if (this.hasOwnProperty('oldValue')) {
        this.value = '';
        // this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
      } else {
        this.value = '';
      }
    });
  };
}(jQuery));

$(window).on('load scroll', function (e) {
	if (e.type === 'load') {
		$('html').addClass('loaded')
		mobilePosition()
		$('.preloader').fadeOut('slow')
		setTimeout(function () {
			$('.preloader').remove()
		}, 1000)
	}

	initPosition()
})

import PerfectScrollbar from 'perfect-scrollbar';
import AutoNumeric from 'autonumeric';
$(document).ready(function () {
	// FORM STYLE
	InitForm.inputFormStyle()
	InitForm.inputFormat()

	// SIDEBAR
	const $sidebarNav = $('#sidebarNav')

	if ($sidebarNav.length > 0) {
		new PerfectScrollbar('.sidebar-nav')
	}

	$('.sidebar-toggle').on('click', function () {
		toggleSidebar(this)
	})

	const $sidebar = $('.sidebar')
	$sidebar.on('mouseover mouseout', function (e) {
		if ($(this).hasClass('toggled') && e.type === 'mouseover') {
			$(this).addClass('hover')
		} else if ($(this).hasClass('toggled') && e.type === 'mouseout') {
			$(this).removeClass('hover')
		}
	})

	// DROPDOWN MENU
	$('.dropdown-menu').on('click', '.form-group', function (e) {
		e.stopPropagation()
	})

	// NAV TAB
	$('.nav-link[data-toggle="pill"]').on('shown.bs.tab', function() {
		$.fn.dataTable.tables({ visible: true, api: true }).columns.adjust()
	})
})

function toggleSidebar(el) {
	$('.sidebar, .sidebar-toggle, body').toggleClass('toggled')
	if ($(el).hasClass('toggled')) {
		$('body').append('<div class="sidebar-backdrop"></div>')
	} else {
		$('.sidebar-backdrop').remove()
    }

    if ($('#tDebtSavingPayment').length == 1) {
        $('#tDebtSavingPayment').DataTable().columns.adjust().draw()
    }
}

function initPosition() {
	if ($('.navbar').length !== 1) return false

	const position = $(window).scrollTop(),
	$navbar = $('.navbar'),
	navbarPosition = $navbar.offset().top

	if (navbarPosition <= position) {
	  $navbar.addClass('bg-white sticked')
	} else {
	  $navbar.removeClass('bg-white sticked')
	}
}

function mobilePosition() {
	if ($('.navbar').length !== 1) return false

	const windowWidth = $(window).width(),
	navbarPosition = $('.navbar').offset().top,
	maxScroll = $(document).height() - $(window).height()

	if (windowWidth <= 992 && navbarPosition <= maxScroll) {
		$('html, body').animate({
			scrollTop: navbarPosition
		}, 500)
	}
}

function readImage(input) {
	const $this = $(input),
	fileName = input.files[0].name


	if (input.files && input.files[0]) {
		var reader = new FileReader();

		reader.onload = function (e) {
			$this.parents('.form-upload').find('.img-preview').attr('src', e.target.result)
			$this.parents('.form-upload').find('.form-upload-name').text(fileName)
		}

		reader.readAsDataURL(input.files[0]);
	}
}

export { InitForm }
